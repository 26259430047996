<template>
  <div class="online_class_panel">
    <Card>
      <template slot="outer">
        <div class="mb-4 mt-6 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon class="text-algal">format_list_bulleted</md-icon>
            <h2 class="pl-2 text-uppercase text-algal">LIST</h2>
          </div>
        </div>
      </template>
      <div class="md-layout md-gutter align-start relative online_class_list">
        <div class="md-layout-item" :class="!this.showChildtable ? ' md-size-100' : 'md-size-40'">
          <CardBody class="p-3 online_class relative panels">
            <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
              <SelectComponent v-if="!this.showChildtable" :placeholder="false"
                :items="short" class="w-64 m-2 font-bold" />
              <SearchBox 
                v-model="keyword"  
                class="p-2 w-64 m-2" 
                placeholder="Search" 
                :isMultiIcon="true"
                @submit="submitSearchKeyword" 
              />
            </div>
            <md-divider></md-divider>
            <Table :items="getClasses" hover-action>
              <template slot="row" slot-scope="{ item }">
                <!-- <md-table-cell md-label="OC_ID" class="font-bold text-left" md-sort-by="id">{{ item.id }}</md-table-cell> -->
                <md-table-cell md-label="ONLINE CLASS TITLE" class="font-bold text-left" md-sort-by="title"><img :src="item.thumbnail" />{{item.title}}</md-table-cell>
                <md-table-cell v-if="!showChildtable" md-label="MODULES" class="font-bold text-center">{{ item.total_modules }}</md-table-cell>
                <md-table-cell v-if="!showChildtable" md-label="CHAPTERS" class="font-bold text-center">{{ item.total_chapters }}</md-table-cell>
                <md-table-cell v-if="!showChildtable" md-label="DURATION(H)" class="font-bold text-center">{{ convertSecondToHour(item.total_duration) }}</md-table-cell>
                <md-table-cell v-if="!showChildtable" md-label="TAGS" class="font-bold text-victoria text-left" md-sort-by="tag">{{ item.tags.join(", ") }}</md-table-cell>
                <md-table-cell v-if="!showChildtable" md-label="Featured" class="font-bold"><md-icon class="rounded-full p-2 text-lg" :class="{'text-green': item.is_featured, 'text-red': !item.is_featured}">{{ item.is_featured ? 'check' : 'close' }}</md-icon></md-table-cell>
                <md-table-cell>
                <span class="">
                  <span v-if="!showChildtable">
                    <md-icon
                      class="bg-default rounded-full p-1 text-gray-700 text-sm mr-1"
                      @click.native="onShowDetails(item)"
                    >visibility</md-icon>
                    <md-icon @click.native="onDuplicate(item)" class="bg-dodger-blue rounded-full p-2 text-white text-sm">content_copy</md-icon>
                    <md-icon  @click.native="onCopy(item)" class="bg-tahiti-gold rounded-full p-2 text-white text-sm">link</md-icon>
                    <md-icon @click.native="goToEditPage(item)" class="bg-algal rounded-full p-2 text-white text-sm">edit</md-icon>
                  </span>
                  <md-icon @click.native="onShare()" :class="{'bg-japanese-laurel rounded-full p-2 text-white text-sm arrow-plane' : true, 'arrow-plane-rotated': showChildtable}">navigation</md-icon>
                </span>
              </md-table-cell>
              </template>
            </Table>
            <div class="flex justify-start my-8" v-if="getClassesMeta">
              <Paginate
                bgColor="bg-algal"
                :start="getClassesMeta.current_page"
                :end="getClassesMeta.last_page"
                :total="getClassesMeta.last_page"
                :limit="getClassesMeta.per_page"
                @on-start="onStart"
                @on-end="onEnd"
              />
            </div>
          </CardBody>
        </div>

        <div v-if="this.showChildtable" class="md-layout-item md-size-60">
          <CardBody class="p-3 online_class relative panels">
            <ChildTable/>
          </CardBody>
        </div>
        <div @click="onCreateNew()" style="position: absolute; bottom: 10px; right: 15px"
            class="bg-algal pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white text-lg">add</md-icon>
        </div>
      </div>
    </Card>
    <Dialog :class="component">
      <components :content="content" :is="component"></components>
    </Dialog>
    <div v-if="getLoading">
        <Loader />
    </div>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, SwitchComponent, Dialog, Table, Paginate } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import TheOnlineClassCreate from "@/components/molecule/online-class/TheOnlineClassCreate";
import ShowDetails from "@/components/molecule/online-class/ShowDetails";
import ShareClass from "./ShareClasses.vue";
import DuplicateClass from "./DuplicateClass.vue";
import ChildTable from "./ChildTable.vue";
import data from "@/data/online-class/data"
import { secondToHour } from '@/utils';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { query } from "@/utils/queryString";
import Loader from '@/components/atom/loader.vue';

export default {
  name: "online-class-List",
  components: {
    Card,
    Table,
    Dialog,
    CardBody,
    SearchBox,
    ShowDetails,
    SwitchComponent,
    SelectComponent,
    TheOnlineClassCreate,
    ShareClass,
    DuplicateClass,
    ChildTable,
    Paginate,
    Loader,
  },
  data() {
    return {
      boolean: true,
      content: {},
      component: 'ShareClass',
      showChildtable: false,
      onlineClasses: data.onlineClasses,
      short: [
        {id: 1, name: 'SHORT BY DATE'},
      ],
      keyword: '',
    };
  },
  computed: {
    ...mapGetters({
			getLoading: "onlineClass/getLoading",
			getClasses: "onlineClass/getClasses",
			getClassesMeta: "onlineClass/getClassesMeta",
		}),
  },
  methods: {
    ...mapActions({
        fetchOnLineClassList: "onlineClass/fetchOnLineClassList",
        fetchOnLineClassById: "onlineClass/fetchOnLineClassById",
        removeAllClassInfo: "onlineClass/removeAllClassInfo",
    }),
    ...mapMutations({
      dialog: "setShowDialog",
      setClassInfo: "onlineClass/setClassInfo",
    }),
    convertSecondToHour(duration) {
      if(duration) {
        return secondToHour(duration);
      }
    },
    goToEditPage(item) {
      // this.setClassInfo(item);
      if(!item.id) return;

      this.fetchOnLineClassById(item.id)
      .then(() => {
        this.$router.push({
          name: 'onlineClassCreation.classInformation'
        });
      });
    },
    onStart(value) {
      if(value == '') {
        query.remove('page');
      }else {
        query.set('page', value);
      }
      this.fetchOnLineClassList(`?${query.get()}`);
    },
    onEnd(value) {
      if(value == '') {
        query.remove('page');
      }else {
        query.set('page', value);
      }

      this.fetchOnLineClassList(`?${query.get()}`);
    },
    submitSearchKeyword() {
      query.set('search', this.keyword);
      this.fetchOnLineClassList(`?${query.get('search', this.keyword)}`);
    },
    onCreateNew() {
      
      this.$router.push({
        name: 'onlineClassCreation',
      })
    },
    onShowDetails(item) {

      if(!item.id) return;

      this.fetchOnLineClassById(item.id);
      
      this.component = 'ShowDetails';
      this.content = item;
      this.dialog(true);
      this.$router.push({
        name: 'onlineClass.online-class-list.details-modules',
      })
    },
    onCopy(item) {
      if(!item.id) return;

      this.fetchOnLineClassById(item.id)
      .then(() => {
        this.component = 'ShareClass';
        this.dialog(true);
      });
    },
    onShare() {
      
      if(this.showChildtable === true) {
        this.showChildtable = false;
      } else {
        this.showChildtable = true;
      }
    },
    onDuplicate(item) {
      if(!item.id) return;

      this.fetchOnLineClassById(item.id);
      
      this.component = 'DuplicateClass';
      this.content = item;
      this.dialog(true);
    },
    onResetPage() {
      query.remove('page');
      query.set('page', 1);
   },
   onResetSearch() {
      this.onResetPage();
      query.remove('search');
   },
  },
  watch: {
    keyword(oldvalue, newValue) {
      query.set('page', 1);
      query.set('search', oldvalue)
      // For reseting search result
      if(oldvalue.length < newValue.length && oldvalue.length == 0) {
        this.fetchOnLineClassList(`?${query.get()}`);
      }
      // For submitting search api after 3 cherecter
      if(oldvalue.length >= 3) {
        this.fetchOnLineClassList(`?${query.get()}`);
      }
    },
  },
  
  created() {
    this.onResetSearch();
    this.fetchOnLineClassList();
    this.removeAllClassInfo();
  }
};
</script>

<style scoped>
img {
  width: 80px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
  margin: 5px;
}

.panels {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
}
.align-start {
  align-items: flex-start !important;
}

</style>