<template>
    <div :class="customClass">
        <div v-if="isDuplicate">
            <div class="flex justify-end">
               <md-button @click="onDuplicateCreate" :disabled="loading" class="bg-dodger-blue h-full mr-0 px-3 py-3 text-white rounded-xl text-capitalize">
                    <md-icon v-if="loading" class="loader text-sm text-white">cached</md-icon>
                    <md-icon v-else class="md-icon md-icon-font text-white md-theme-default mr-1 text-xl">
                        content_copy
                    </md-icon>
                    Duplicate
                </md-button>
            </div> 
        </div>
        <div class="md-layout-item md-size-100">
            <h2 class="text-black text-bold mt-5 mb-2">{{ title }} <span class="text-xs text-blue bg-trans-blue px-2 py-1 rounded">
                {{ senitizeDurationToHour }} Hours
            </span></h2>
            <p class="text-justify text-base" v-html="getClassDetail?.description"></p>
        </div>
        <div class="md-layout-item md-size-100 mt-4 flex">
            <div class="md-layout md-size-100">
                <div class="md-layout-item md-size-30 p-0">
                    <p class="font-bold">
                        <md-icon class="text-blue text-sm">text_snippet</md-icon>
                        {{ getClassDetail?.total_chapters }} Chapters
                    </p>
                </div>
                <div class="md-layout-item md-size-25">
                    <p class="font-bold">
                        <md-icon class="text-blue text-sm">wysiwyg</md-icon>
                        {{ getClassDetail?.total_materials }} Materials
                    </p>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100 mt-3 flex">
            <div class="md-layout  md-size-100">
                <div class="md-layout-item md-size-30 p-0">
                    <p class="font-bold">
                        <md-icon class="text-blue text-sm">carpenter</md-icon>
                        {{ getClassDetail?.total_practice_tests }} Tests
                    </p>
                </div>
                <div class="md-layout-item md-size-25">
                    <p class="font-bold">
                        <md-icon class="text-blue text-sm">description</md-icon>
                        {{ getClassDetail?.total_quizes }} Quizes
                    </p>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100 text-left mt-6">
            <div>
                <p class="font-bold">
                    <md-icon class="text-blue text-sm">group</md-icon>
                    {{ getClassDetail?.total_student_taken ?? 0 }} Student Taken
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { secondToHour } from '@/utils';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    props: {
        customClass: {
            type: String
        },
        isDuplicate: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            getClassDetail: "onlineClass/getClassDetail",
            getClasses: "onlineClass/getClasses",
        }),
        title() {
            return this.getClassDetail?.title;
        },
        senitizeDurationToHour() {
            if(this.getClassDetail.total_duration) {
                return secondToHour(this.getClassDetail.total_duration)
            }
            return '00';
        },
    },
    methods: {
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
            dialog: "setShowDialog",
			setClassList: "onlineClass/setClassList",
		}),
        ...mapActions({
			onOnlineClassDuplicateCreate: "onlineClass/onOnlineClassDuplicateCreate",
            onOnlineClassPublish: "onlineClass/onOnlineClassPublish"
		}),
        onDuplicateCreate() {
			this.loading = true;

            let formData = {online_class_id: this.getClassDetail.id};
            
            if(!formData.online_class_id) return;

            this.onOnlineClassDuplicateCreate(formData)
			.then((response) => {
                if(response && response.items) {
                    let list = [];
                    list = JSON.parse(JSON.stringify(this.getClasses));
                    if(list.length) {
                        list.unshift(response.items);
                        list.pop();
                        this.dialog(false);
                        this.$router.push({
                            name: 'onlineClass.online-class-list'
                        });
    
                        this.setClassList(list);
                    } else {
                        list.push(response.items);
                    }
                }
				this.setShowSnackbar("Successfully Duplicate Save");
			})
			.catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error);
			})
			.finally(() => this.loading = false);

        },
    }
}
</script>

<style>
.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}
</style>