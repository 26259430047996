<template>
    <div class="w-85p border rounded-lg border-solid border-gray-400 px-6 py-4 my-4 mx-auto">
        <div class="md-layout flex align-center mt-1 justify-between">
            <div>
               <h2 class="text-2xl">Class Description</h2>
            </div>
        </div>
        <div class="mt-4 w-100p">
           <p class="text-justify text-base" v-html="getClassDetail?.description"></p>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            getClassDetail: "onlineClass/getClassDetail",
        }),
    },
}
</script>