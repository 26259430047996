<template>
    <div class="md-layout md-gutter m-5 pb-3 mt-6 mb-0">
        <div class="md-layout-item md-size-50" style="height: 428px; !important">
            <img class="rounded-lg" style="height: 100%;" :src="thumbnailImageUrl"/>
        </div>
        <div class="md-layout-item md-size-50">
            <h1 class="text-black text-bold text-4xl mt-5 mb-2 line-height-44">Are you sure to duplicate this class?</h1>
            <h3 class="text-black text-2xl text-bold mt-5 mb-2 border border-solid border-gray-400 br-4 p-4">{{ title }}</h3>
            <p class="mt-4 border border-solid border-gray-400 br-4 p-2 text-base" v-html="getClassDetail?.description"></p>
        </div>
        <div class="w-full flex justify-center align-center mt-8 mb-5 mr-2">
            <Button
                class="size-lg text-lg text-capitalize h-12 rounded-lg px-3 text-black mr-0 border border-solid border-gray-500 mr-4"
                label="cancel"
                type="submit"
                @click="dialog(false)"
                >
            </Button>
            <Button
                class="px-3 bg-algal rounded-lg text-white mr-0 size-lg text-lg text-capitalize h-12"
                label="Save"
                type="button"
                @click.native="onDuplicateCreate()"
                :loading="loading"
            >
            </Button>
        </div>
    </div>
</template>
<script>
import {Button} from "@/components";
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    components: {
        Button,
    },
    data(){
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            getClassDetail: "onlineClass/getClassDetail",
            getClasses: "onlineClass/getClasses",
        }),
        title() {
            return this.getClassDetail?.title;
        },
        thumbnailImageUrl() {
            if(this.getClassDetail && this.getClassDetail.thumbnail){
                return this.getClassDetail.thumbnail;
            }

            return "@/assets/images/news_placeholder_detailed_page.png";
        }
    },
    methods: {
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
            dialog: "setShowDialog",
			setClassList: "onlineClass/setClassList",
		}),
        ...mapActions({
			onOnlineClassDuplicateCreate: "onlineClass/onOnlineClassDuplicateCreate",
            onOnlineClassPublish: "onlineClass/onOnlineClassPublish"
		}),
        onDuplicateCreate() {
			this.loading = true;

            let formData = {online_class_id: this.getClassDetail.id};
            
            if(!formData.online_class_id) return;

            this.onOnlineClassDuplicateCreate(formData)
			.then((response) => {
                if(response && response.items) {
                    let list = [];
                    list = JSON.parse(JSON.stringify(this.getClasses));
                    if(list.length) {
                        list.unshift(response.items);
                        list.pop();
                        this.dialog(false);
                        this.setClassList(list);
                    } else {
                        list.push(response.items);
                    }
                }
				this.setShowSnackbar("Successfully Duplicate Save");
			})
			.catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error);
			})
			.finally(() => this.loading = false);

        },
    }
}
</script>

