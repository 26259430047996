<template>
    <div>
        <h2 class="text-left py-6 pt-4">Population Of Canada</h2>
        <md-divider></md-divider>
        <Table :items="onlineClasses" hover-action>
            <template slot="row" slot-scope="{ item }">
            <md-table-cell md-label="STD_ID" md-numeric class="font-bold">{{ item.oc_id }}</md-table-cell>
            <md-table-cell md-label="STUDENT NAME" class="font-bold">{{item.name}}</md-table-cell>
            <md-table-cell md-label="COMPILATION" class="font-bold text-center">
                <span class="align-center flex justify-between">
                    <md-progress-bar class="rounded mx-2 w-24" md-mode="determinate" :md-value="item.progress"></md-progress-bar>
                    <small class="text-sm font-bold text-black" style="margin-top: -4px;">{{item.progress}}%</small>
                </span>
            </md-table-cell>
            <md-table-cell md-label="LAST ACTIVITY" class="font-bold text-center">{{ item.date }}</md-table-cell>
            <span class="action p-3">
                <md-icon
                class="bg-default rounded-full p-1 text-gray-700 text-sm mr-1"
                >visibility</md-icon>
            </span>
            </template>
        </Table>
    </div>
</template>

<script>
import { Table } from "@/components";
import data from "@/data/online-class/data"
export default {
  name: "Todo-List",
  components: {
    Table,
  },
  data() {
    return {
      onlineClasses: data.onlineclassesChild,
    };
  },
  methods: {
   
  },
}
</script>