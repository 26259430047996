<template>
    <div class="mt-6 w-85p m-auto">
        <ChapterCard isVisible="invisible" v-for="(moduleObj, index) in modules" :key="index" :item="moduleObj"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChapterCard from "@/components/molecule/online-class/ChapterCard.vue";

export default {
    components: {
        ChapterCard
    },
    computed: {
        ...mapGetters({
            getClassDetail: "onlineClass/getClassDetail",
        }),
        modules() {
            return this.getClassDetail.modules ?? []
        }
    },
}
</script>
