<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle bg-color="bg-victoria" label="Create Online Class" align="center" />
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-size-25 mb-3">
        <SelectComponent
          label="Choose Branch"
          italic class="w-full"
          v-model.trim="$v.form.branchId.$model"
          :message="!$v.form.branchId.required && $v.form.branchId.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item mb-3">
        <InputFieldComponent
          italic
          label="Choose Date"
          v-model.trim="$v.form.date.$model"
          :message="!$v.form.date.required && $v.form.date.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item mb-3">
        <InputFieldComponent
          italic
          label="Choose Time"
          v-model.trim="$v.form.time.$model"
          :message="!$v.form.time.required && $v.form.time.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item mb-3">
        <InputFieldComponent
          italic
          label="Duration"
          v-model.trim="$v.form.duration.$model"
          :message="!$v.form.duration.required && $v.form.duration.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-25 mb-3">
        <SelectComponent
          label="Select Course"
          italic
          class="w-full"
          v-model.trim="$v.form.courseId.$model"
          :message="!$v.form.courseId.required && $v.form.courseId.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout md-gutter mb-3">
      <div class="md-layout-item md-size-25">
        <SelectComponent
          label="Medium"
          italic
          class="w-full"
          v-model.trim="$v.form.medium.$model"
          :message="!$v.form.medium.required && $v.form.medium.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-25">
        <md-button class="bg-victoria w-full mt-6 rounded text-white">Create Online Link</md-button>
      </div>
    </div>
    <div class="md-layout md-gutter mb-3">
      <div class="md-layout-item md-size-25">
        <SelectComponent
          label="Choose Group"
          italic
          class="w-full"
          v-model.trim="$v.form.groupId.$model"
          :message="!$v.form.groupId.required && $v.form.groupId.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-35">
        <SelectComponent
          label="Sleect Assignee"
          italic
          class="w-full"
          v-model.trim="$v.form.assigneeId.$model"
          :message="!$v.form.assigneeId.required && $v.form.assigneeId.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div
        class="flex justify-end align-center md-layout-item md-size-100 mb-3"
      >
        <md-button class="md-default">Save As Draft</md-button>
        <md-button type="submit" class="bg-victoria rounded text-white">Create</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import { SelectComponent, InputFieldComponent, DialogTitle} from "@/components";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";

export default {
  components: {
    DialogTitle,
    SelectComponent,
    InputFieldComponent,
  },
  data() {
    return {
      radio: true,
      form: {
        branchId: "",
        date: "",
        time: "",
        duration: "",
        courseId: "",
        medium: "",
        groupId: "",
        assigneeId: "",
      }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Online class saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
      branchId: {required},
      date: {required},
      time: {required},
      duration: {required},
      courseId: {required},
      medium: {required},
      groupId: {required},
      assigneeId: {required}
    }
  }
};
</script>

<style></style>
