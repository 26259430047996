<template>
    <div class="p-6 online-class-details">
        <div class="flex align-start justify-between">
          <ImageSection customClass="md-layout-item md-size-50 mr-10 imageParentClass-428px"/>
          <CardSummary customClass="md-layout-item mr-1" :isDuplicate="true"/>
        </div>
        <div class="mt-4">
          <Tab :items="items"/>
          <router-view />
          <!-- <router-view @on-click="onClick" /> -->
        </div>
        <div class="flex justify-center">
            <md-button class=" size-lg text-lg h-12 rounded-lg px-4 outline-gray-400 text-capitalize rounded" @click="dialog(false)">Back</md-button>
            <md-button class="ml-2 bg-algal text-capitalize rounded-lg text-white size-lg text-lg h-12 px-4" @click.prevent="goToEditPage">
              <md-icon class="md-icon md-icon-font text-white text-lg md-theme-default">edit</md-icon>Edit
            </md-button>
        </div> 
    </div>
</template>

<script>
import ImageSection from "@/views/teacher/online-class/ImageSection.vue";
import CardSummary from "@/views/teacher/online-class/CardSummary.vue";
import Tab from "@/components/atom/Tab";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    ImageSection,
    CardSummary,
    Tab
  },
  props: {
      content: {
          type: Object
      }
  },
  data() {
    return {
      items: [
        {name: 'Modules', path: 'onlineClass.online-class-list.details-modules'},
        {name: 'Class Description', path: 'onlineClass.online-class-list.details-class-description'},
        {name: 'Why This Class Important ?', path: 'onlineClass.online-class-list.details-importants'},
      ]
    }
  },
  computed: {
      ...mapGetters({
          getClassDetail: "onlineClass/getClassDetail",
      }),
  },
  methods: {
    ...mapMutations({
        dialog: "setShowDialog",
        setClassInfo: "onlineClass/setClassInfo",
    }), 
    goToEditPage() {
      this.setClassInfo(this.getClassDetail);
      setTimeout(() => {
        this.$router.push({
          name: 'onlineClassCreation.classInformation'
        });
        this.dialog(false);
      });
    }
  },
}
</script>

<style>
 .online-class-details .tab-component{
    text-align: center;
    border-bottom: 1px solid #e5e3e3;
 }
 .online-class-details .tab-component .border-gray-300{
    border-color: #ffffff;
 }
 .online-class-details .tab-component .border-gray-300 .text-base{
    color: #252525;
    margin-left: 0 !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
 }
 .online-class-details .tab-component .active {
    font-weight: bold;
    border-bottom: 2px solid rgb(73, 73, 73);
    margin-bottom: -3px;
  }
</style>