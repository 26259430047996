<template>
    <div class="p-6 online-class-details">
        <div @click="backToModule" class="back-button cursor py-2">
            <md-icon class="md-icon md-icon-font text-blue text-4xl md-theme-default">arrow_back</md-icon>
        </div>
        <div class="md-layout-item md-size-100">
            <h2 class="text-black text-4xl text-bold mt-5 mb-2 flex">{{ getModule?.title }} <span class="text-xs text-blue bg-trans-blue px-2 py-1 rounded ml-1">{{ senitizeDurationToHour }} Hours</span></h2>
            <p class="text-justify text-base" v-html="getModule?.description"></p>
        </div>
        <div class="mt-4">
          <!-- <Tab :items="items"/> -->
          <Tab 
              v-if="items.length"
              :items="items" 
              wrapperClass="c_simple_tab_three"
              @activeTab="onSetTab"
              titleKey="title"
          >
          </Tab>
          <ChapterView v-if="selectedClass" :item="selectedClass" :is-show-action-btn="false" style="min-height: 70vh;"/>
          <!-- <router-view @on-click="onClick" /> -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tab from '@/components/atom/SimpleTab.vue';
// import Tab from "@/components/atom/Tab";
import ChapterView from "@/views/teacher/online-class/create/preview/ChapterView.vue";
import { secondToHour } from '@/utils';

export default {
  components: {
    Tab,
    ChapterView
  },
  props: {
      content: {
          type: Object
      }
  },
  data() {
    return {
      items: [],
      selectedClass: null,
    }
  },
  computed: {
      ...mapGetters({
          getModule: "onlineClass/getModule",
      }),
      senitizeDurationToHour() {
          if(this.getModule.total_duration) {
              return secondToHour(this.getModule.total_duration)
          }
          return '00';
      },
  },
  methods:{
    onSetTab(tab) {
      let findIndexBySelectedTab = this.getModule.chapters.findIndex((val) => val.title === tab);

      if(findIndexBySelectedTab > -1) {
        this.selectedClass = this.getModule.chapters[findIndexBySelectedTab];
      }

    },
    backToModule() {
        this.$router.push({
            name: 'onlineClass.online-class-list.details-modules',
        })
    }
  },
  watch:{
    'getModule': {
      immediate: true,
      deep: true,
      handler(value) {
        if(value) {
          this.items = value.chapters;
        }
      },
    }
	},
}
</script>

<style>
 .online-class-details .tab-component{
    text-align: center;
    border-bottom: 1px solid #e5e3e3;
 }
 .online-class-details .tab-component .border-gray-300{
    border-color: #ffffff;
 }
 .online-class-details .tab-component .border-gray-300 .text-base{
    color: #252525;
    margin-left: 0 !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
 }
 .online-class-details .tab-component .active {
    font-weight: bold;
    border-bottom: 2px solid rgb(73, 73, 73);
    margin-bottom: -3px;
  }
</style>