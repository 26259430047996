<template>
    <div class="flex align-center">
        <div class="mr-4" :class="isVisible">
            <md-icon class="md-icon md-icon-font text-gray text-md md-theme-default">lock</md-icon>
        </div>
    
        <div class="w-100p border rounded-lg border-solid border-gray-400 px-6 py-4 my-4">
            <div class="md-layout flex align-center mt-1 justify-between">
                <div>
                <h2 class="text-2xl">{{ item?.title }}</h2>
                </div>
                <div @click="showChapter" class="cursor">
                  <p class="text-sm font-medium text-blue font-inter">{{ item?.total_chapters }} Chapters <md-icon class="md-icon md-icon-font text-blue md-theme-default">add_circle_outline</md-icon></p>
                </div>
            </div>
            <div class="mt-4 w-75p">
            <p class="text-justify text-base" v-html="item?.description"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    content: {
        type: Object
    },
    isVisible: {
        type: String,
        default: 'visible'
    },
    item: {
        type: Object,
        default: null,
    }
  },
  methods: {
    ...mapMutations({
        setModule: "onlineClass/setModule",
    }),
    showChapter() {
        this.setModule(this.item);

        this.$router.push({
            name: 'onlineClass.online-class-list.chapter-details',
        })
    }
  }
}
</script>