<template>
    <div class="mt-6">
        <div class="md-layout flex align-center mt-1 justify-between">
            <div>
               <h2 class="text-2xl text-blue">Important of English</h2>
            </div>
        </div>
        <div class="mt-4 w-100p border-b border-solid border-gray-300 pb-10">
           <p class="text-justify text-base">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
            text ever since the 1500s, when an unknown printer.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
            text ever since the 1500s, when an unknown printer
        </p>
        </div>
        <div v-for="(item, index) in items" :key="index" class="mt-10">
            <ChapterHeader/>
            <components :is="item.name" :items="item"></components>
        </div>
    </div>
</template>

<script>
import ChapterHeader from "@/components/molecule/online-class/items/ChapterHeader.vue";
import ChapterAudio from "@/components/molecule/online-class/items/Audio.vue";
import ImageAndContents from "@/components/molecule/online-class/items/ImageAndContents.vue";
import Videos from "@/components/molecule/online-class/items/Video.vue";
export default {
    components: {
        ChapterHeader,
        ChapterAudio,
        ImageAndContents,
        Videos
    },
    data() {
        return {
            items: [
                {name: 'ChapterAudio'},
                {name: 'ChapterAudio'},
                {name: 'Videos'},
                {name: 'ImageAndContents', title: 'Important Of The Language.pdf', type: 'pdf'},
                {name: 'ImageAndContents', title: 'Practice Test', type: 'docs'},
            ]
        }
    },
}
</script>
