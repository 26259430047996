<template>
    <router-view />
    <!-- <router-view @on-click="onClick"/> -->
</template>

<script>

export default {
 methods: {
    onClick() {
        console.log("Hi");
    }
 }
  
}
</script>

