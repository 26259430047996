<template>
    <div>
        <div class="md-layout flex align-center mt-1 justify-between">
            <div>
                <h2 class="text-2xl">1. Title of Part One <span class="text-xs text-gray-800 bg-alto px-2 rounded">Incomplete</span></h2>
            </div>
        </div>
        <div class="mt-4 w-75p">
        <p class="text-justify text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    content: {
        type: Object
    },
  },
}
</script>