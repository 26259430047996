<template>
    <div :class="customClass">
        <img class="rounded-lg imageCustomClass" :src="imageUrl" :alt="imageUrl"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        customClass: {
            type: String
        }
    },
    computed: {
        ...mapGetters({
            getClassDetail: "onlineClass/getClassDetail",
        }),
        imageUrl() {
            if(this.getClassDetail.banner) {
                return this.getClassDetail.banner;
            } else if(this.getClassDetail.thumbnail) {
                return this.getClassDetail.thumbnail;
            }
            return 'No Image';
        }
    },
    methods: {

    }
}
</script>