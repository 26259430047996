<template>
    <div class="md-layout md-gutter m-5 pb-3 mt-6 mb-0">
        <ImageSection customClass="md-layout-item md-size-100"/>
        <CardSummary customClass="md-layout-item md-size-100"/>
        <div class="md-layout-item md-size-100 mt-8 mb-6">
            <div class="flex">
                <input class="font-bold p-4 text-lg" value="https://demo-frontendtest" type="text"/>
                <button class="bg-tahiti-gold text-white text-lg w-25p rounded-tr rounded-br">
                    <md-icon class="text-white text-md" style="transform: rotate(45deg);">attach_file</md-icon>
                    copy Link
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import CardSummary from './CardSummary.vue';
import ImageSection from './ImageSection.vue'

export default {
    components: {
        CardSummary,
        ImageSection
    },
}
</script>


<style scoped>
img {
    max-height: 260px;
    width: 100%;
    object-fit: cover;
}
input {
    width: 60%;
    padding: 8px;
    border: 1px solid #cbc5c5;
    border-radius: 5px;
}
button {
    width: 110px;
    margin-left: -5px;
    border: none;
}
</style>