<template>
  <div class="text-center online_class_body">
      <Tab :items="items">
        <template v-slot:icon="{ item }">
          
          <md-icon class="m-0">{{ item.icon }}</md-icon>
        </template>
      </Tab>
      <router-view />
  </div>
</template>

<script>
import Tab from "@/components/atom/Tab";
import data from "@/data/online-class/data"
export default {
    components: { Tab },
    data() {
    return {
      items: data.onlineClassTab
    };
  },
}
</script>